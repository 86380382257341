(function () {
    angular.module('aerosApp')
        .directive('aflLinkmapFiberSection', aflLinkmapFiberSection);

    function aflLinkmapFiberSection() {
        return {
            restrict: 'E',
            scope: {
                event: '=',
                imageMap: '=',
                iconMap: '=',
                fiberSets: '=',
                groupRows: '=',
                unit: '=',
                lossDistUnit: '='
            },
            controller: aflLinkmapFiberSectionController,
            templateUrl: '/static/javascript/directives/afl-linkmap/afl-linkmap-fiber-section.html'
        }
    }

    aflLinkmapFiberSectionController.$inject = ['$scope', 'UnitsMeasurementService', 'LinkmapService'];

    function aflLinkmapFiberSectionController($scope, UnitsMeasurementService, LinkmapService) {

        angular.extend($scope, {
            getLocationPrecision: LinkmapService.getLocationPrecision,
            getPrecision: getPrecision
        });

        function getPrecision(field, value) {
            if (typeof value === 'undefined') {
                return '--';
            }

            if (typeof value !== 'string') {
                switch (field.name) {
                    case 'Loss(dB)': {
                        return value.toFixed(3);
                        break;
                    }
                    case 'Refl(dB)': {
                        return value.toFixed(2);
                        break
                    }
                    case 'Cum Loss(dB)': {
                        return value.toFixed(3);
                        break
                    }
                    case 'Atten(' + ($scope.lossDistUnit || 'dB/km') + ')':
                        if ($scope.lossDistUnit !== 'dB/km') {
                            var converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);
                            if (converters.showUnitConverter) {
                                return converters.showUnitConverter(value).toFixed(3);
                            }
                        }
                        return value.toFixed(3);
                }
            }

            return value;
        };
    }
})();
